import { useCallback, useEffect, useState, ChangeEvent } from 'react'
import { useGetPrograms } from '~/hooks/useGetPrograms'
import { useReplaceProgramNumberMutation, useAuthorizeEntryMutation } from '~/store/api/entries'
import { useGetX5AuthorizationData } from '~/hooks/useGetX5AuthorizationData'
import { validatorAFM } from './validators/validatorAFM'

type AuthorizationStatus = 'void' | 'isSuccess' | 'isError'

export function useAuthorization(programMnemocode: string) {
  const [authorizeEntry, { isSuccess: isAuthorizeOk, isError: isAuthorizeError, isLoading: isAuthorizeLoading }] =
    useAuthorizeEntryMutation()
  const [
    replaceProgramNumber,
    { isSuccess: isReplaceNumberOk, isError: isReplaceNumberError, isLoading: isReplaceNumberLoading },
  ] = useReplaceProgramNumberMutation()
  const [authorizationProcessStatus, setAuthorizationProcessStatus] = useState<AuthorizationStatus>('void')

  useEffect(() => {
    if (isAuthorizeOk || isReplaceNumberOk) {
      setAuthorizationProcessStatus('isSuccess')
    }
    if (isAuthorizeError || isReplaceNumberError) {
      setAuthorizationProcessStatus('isError')
    }
  }, [isAuthorizeError, isAuthorizeOk, isReplaceNumberError, isReplaceNumberOk])

  const resetStatus = useCallback(() => {
    setAuthorizationProcessStatus('void')
  }, [])

  const program = useGetPrograms(programMnemocode)
  const { entryName, details, entryClass, entryMnemocode, hasEntry } = program
  const { inputRegularExpression } = details

  const replacementMode = Boolean(entryName)
  const digitsDefault = entryName ?? ''
  const [cardNumber, setCardNumber] = useState<string>(digitsDefault)

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCardNumber(e.target.value)
      if (authorizationProcessStatus !== 'void') resetStatus()
    },
    [authorizationProcessStatus, resetStatus]
  )

  const checkNumberValidity = checkNumberValidityTemplate(entryClass, inputRegularExpression)

  function doAuthorization(programNumber: string) {
    if (hasEntry) {
      replaceProgramNumber({
        mnemocode: entryMnemocode!,
        replaceNumber: getFormattedProgramNumber(programNumber, entryClass),
      })
    } else {
      authorizeEntry({
        entry_nr: getFormattedProgramNumber(programNumber, entryClass),
        entry_class: entryClass,
      })
    }
  }

  const [isConsentedDraft, setIsConsentedDraft] = useState(false)
  const toggleConsent = useCallback(() => {
    setIsConsentedDraft((prevState) => !prevState)
  }, [])

  const { userPhone } = useGetX5AuthorizationData()
  const phoneDefault = entryName ?? userPhone
  const [phoneNumber, setPhoneNumber] = useState<string>(phoneDefault ?? '')

  const handlePhoneInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPhoneNumber(e.target.value)
      if (authorizationProcessStatus !== 'void') resetStatus()
    },
    [authorizationProcessStatus, resetStatus]
  )

  return {
    cardNumber,
    resetStatus,
    handleInput,
    replacementMode,
    doAuthorization,
    checkNumberValidity,
    authorizationProcessStatus,
    isConsented: replacementMode || isConsentedDraft,
    toggleConsent,
    phoneNumber,
    handlePhoneInput,
    isAuthorizeLoading,
    isReplaceNumberLoading,
  }
}

type ProgramsToAllowValidation = 'AFM'

function checkNumberValidityTemplate(programProductClass: string, programMask: string) {
  const additionalValidation: Record<ProgramsToAllowValidation, (programNumber: string) => boolean> = {
    AFM: validatorAFM,
  }

  return (programNumber: string) => {
    try {
      const regExp = new RegExp(programMask)
      const program = getFormattedProgramNumber(programNumber, programProductClass)
      const isNumberValid = Boolean(program.length) && regExp.test(program)
      const validator = additionalValidation[programProductClass as ProgramsToAllowValidation]

      return typeof validator === 'function' && isNumberValid ? validator(programNumber) : isNumberValid
    } catch (err) {
      return false
    }
  }
}

function getFormattedProgramNumber(programNumber: string, programProductClass: string) {
  const programsDontReplaceNr = [
    'SLT',
    'MVB',
    'FKH',
    'FSF',
    'FSR',
    'FZS',
    'FDM',
    'FNL',
    'FPZ',
    'FNH',
    'FHV',
    'FHM',
    'FZC',
  ]

  const dontReplaceNr = programsDontReplaceNr.includes(programProductClass)

  return dontReplaceNr ? programNumber : programNumber.replace(/[^0-9]/g, '')
}
