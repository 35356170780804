import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { IconBase } from './IconBase'

import {
  Cards,
  Home,
  Flame,
  Action,
  Bonus,
  Book,
  Exchanged,
  Download,
  Exit,
  Bubble,
  ExternalLink,
  Mail,
  Phone,
  Rules,
  Purchases,
  Settings,
  Star,
  Tutorial,
  Welcome,
  RoundCheck,
  RoundStop,
  Warning,
  Clock,
  Calc,
  Calendar,
  Change,
  Refund,
  Details,
  Stars,
  History,
  CalendarLate,
  Structure,
  Question,
  Arrow,
  Conversion,
  Time,
  Categories,
  Boxes,
  Cross,
  Check,
  Offers,
  Help,
  Cash,
  Basket,
  Ticket,
  User,
  Globe,
  Bolt,
  Bank,
  Hello,
  Gift,
  Wallet,
  ConversionToRoubles,
  ReturnFromMarketplace,
  Link,
  VK,
  Telegram,
  Viber,
  GiftCertificate,
  Cashback,
  Chevron,
  MagnifyingGlass,
  Sort,
  EyeOn,
  EyeOff,
  Copy,
  Edit,
} from './content/iconsWireframeList'

const iconContent = {
  Cards,
  Home,
  Flame,
  Action,
  Bonus,
  Book,
  Exchanged,
  Download,
  Exit,
  Bubble,
  ExternalLink,
  Mail,
  Phone,
  Rules,
  Purchases,
  Settings,
  Star,
  Tutorial,
  Welcome,
  RoundCheck,
  RoundStop,
  Warning,
  Clock,
  Calc,
  Calendar,
  Change,
  Refund,
  Details,
  Stars,
  History,
  CalendarLate,
  Structure,
  Question,
  Arrow,
  Conversion,
  Time,
  Categories,
  Boxes,
  Cross,
  Check,
  Offers,
  Help,
  Cash,
  Basket,
  Ticket,
  User,
  Globe,
  Bolt,
  Bank,
  Hello,
  Gift,
  Wallet,
  ConversionToRoubles,
  ReturnFromMarketplace,
  Link,
  VK,
  Telegram,
  Viber,
  GiftCertificate,
  Cashback,
  Chevron,
  MagnifyingGlass,
  Sort,
  EyeOn,
  EyeOff,
  Copy,
  Edit,
}

export type IconWireFrameType = keyof typeof iconContent

interface Props {
  size?: number
  fill?: string
  color?: string
  strokeWidth?: number
  isBrandColored?: boolean
  icon: IconWireFrameType | undefined
}

export function IconWireFrame({ icon, size, color, strokeWidth, fill, isBrandColored }: Props) {
  const { theme } = useSelector(whiteLabelSelector)
  const { brand_color } = theme

  if (!icon) {
    return null
  }

  const Icon = iconContent[icon]

  return (
    <IconBase size={size} color={isBrandColored ? brand_color : color} strokeWidth={strokeWidth} fill={fill}>
      <Icon />
    </IconBase>
  )
}
