import styled from 'styled-components'
import { Checkbox } from '@/checkbox'

interface Props {
  text: string | undefined
  isConsented?: boolean
  handleConsent?: () => void
}

export function SignupNotice({ text, isConsented, handleConsent }: Props) {
  if (!text) return null

  const hasConsentBlock = typeof isConsented === 'boolean' && typeof handleConsent === 'function'

  return (
    <Root>
      {hasConsentBlock && <Checkbox brandColored isEmbedded checked={isConsented} onChange={handleConsent} />}
      <SignupNoticeText dangerouslySetInnerHTML={{ __html: text }} />
    </Root>
  )
}

const Root = styled.div`
  display: block;
  margin-top: auto;
  padding-top: 16px;
  text-align: center;
`
export const SignupNoticeText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #000000;

  & a {
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: ${({ theme }) => theme.brand_color};
    }
  }
`
