import { Dropdown } from '@/Dropdown/Dropdown'
import Link from 'next/link'
import { useGetOptionsByProduct } from '~/hooks/useGetOptionsByProduct'
import { useEffect, useState } from 'react'
import { processActions } from '~/store/slices/process'
import { useGetCurrentOption } from '~/hooks/useGetCurrentOption'
import styled from 'styled-components'
import { useGetAvailableOptionsForProgramsLink, useIsConflictOption } from '~/hooks/useIsConflictOption'
import { AnimatedContainer } from '@/MultiStepModal/components/AnimatedContainer'
import { ModalControlsBlock } from '@/MultiStepModal/components/ModalControlsBlock'
import { useGetPrograms } from '~/hooks/useGetPrograms'
import { useGetProducts } from '~/hooks/useGetProducts'
import { useIntl } from 'react-intl'
import { useActionCreators, useTypedSelector } from '~/store/redux-store'
import { SignupNotice } from '~/components/flows/components/common/SignupNotice'
import { Meta } from './common/Meta'
import { Container, Title } from './common/stylesCommon'

export function OptionsLink({
  skipNoConflict,
  onNextClick,
  onlyAvailable,
}: {
  onNextClick?: () => void
  skipNoConflict?: boolean
  onlyAvailable?: boolean
}) {
  const intl = useIntl()
  const { setProductOption } = useActionCreators(processActions)

  const selectedProgram = useTypedSelector(({ process }) => process.selectedProgram)
  const selectedCard = useTypedSelector(({ process }) => process.selectedCard)
  const selectedProductOption = useTypedSelector(({ process }) => process.selectedProductOption)
  const program = useGetPrograms(selectedProgram as string)
  const product = useGetProducts(selectedCard as string)

  const { entryClass: productEntryClass, details: productDetails, entryName } = product
  const { icon } = productDetails
  const { details: programDetails } = program
  const { name: programName } = programDetails

  const availableOptionsFromStore = useGetAvailableOptionsForProgramsLink()
  const availableOptionsByProduct = useGetOptionsByProduct(productEntryClass)

  const availableOptions = onlyAvailable ? availableOptionsFromStore : availableOptionsByProduct
  const options = availableOptions?.map((option) => ({
    id: option.option_id,
    text: intl.messages[`app.options.${option?.mnemocode}.name`] as unknown as string,
  }))

  const { currentOptionId, currentOptionName } = useGetCurrentOption(selectedCard!)
  const [selectedId, setSelectedId] = useState(currentOptionId)
  const isConflict = useIsConflictOption()
  useEffect(() => {
    if (!isConflict && skipNoConflict && onNextClick) {
      onNextClick()
    }
  }, [skipNoConflict])

  useEffect(() => {
    if (selectedProductOption) {
      setSelectedId(selectedProductOption)
    }
  }, [selectedProductOption])

  const onSelect = (productOptionId: number | null) => {
    if (productOptionId) setProductOption(productOptionId)
  }

  const selectedOption = options?.find(({ id }) => id === selectedId)?.text

  return (
    <AnimatedContainer animationKey="OptionsLink">
      <Meta
        icon={icon}
        title={entryName}
        rows={[
          {
            param: intl.messages[`app.misc.option`] as unknown as string,
            value: currentOptionName,
          },
          {
            param: intl.messages[`app.misc.program`] as unknown as string,
            value: programName,
          },
        ]}
      />
      <Container gapRow={24}>
        <Title>{intl.messages[`app.program.option_link.choose_new_option`] as unknown as string}</Title>
        <Dropdown
          placeholder={intl.messages[`app.program.option_link.choose_new_option`] as unknown as string}
          label={intl.messages[`app.program.option_link.new_option`] as unknown as string}
          type="big"
          onSelect={(id) => onSelect(id)}
          selectedId={selectedId}
          options={options}
        />
        {selectedOption && (
          <AboutLink>
            {intl.messages[`app.program.option_link.more_about_option`] as unknown as string}:{' '}
            <Link href="/about/options">{selectedOption}</Link>
          </AboutLink>
        )}
        <SignupNotice text={intl.messages[`app.program.option_link.signup_notice`] as unknown as string} />
      </Container>
      <ModalControlsBlock disabledNext={!selectedOption} />
    </AnimatedContainer>
  )
}

export const AboutLink = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  color: #67616a;
  & a {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #000000;
  }
`

export const WarningMessage = styled.div`
  margin-top: 1em;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #000000;
  background: #fde6e9;
  border-radius: 8px;
`
