import { PlatformStatus, ProgramModel } from '~/models'
import { useSelector } from 'react-redux'
import { ProgramsByCurrencySelector, ProgramWithBalanceSelector } from '~/store/selector/programs'
import { activeEntriesSelector } from '~/store/api/entries'
import { ProductSelector } from '~/store/selector/products'
import { productProgramsByProductSelector } from '~/store/api/options'
import { specialCashbackPrograms } from '~/lib/utils/checkProgramType'

type ProgramOf<T> = T extends string ? ProgramModel : ProgramModel[]

const programFallback: ProgramModel = {
  programMnemocode: '',
  hasEntry: false,
  entryMnemocode: undefined,
  entryName: undefined,
  entryStatus: PlatformStatus.NotAuthorized,
  entryClass: '',
  optionsByProgram: [],
  presentProduct: [],
  futureProduct: [],
  isBankProgram: false,
  lastCountedPeriod: undefined,
  balanceTotal: null,
  balanceTotalProfit: null,
  hasTransactions: false,
  availableProducts: [],
  availableOptions: [],
  createdWhen: undefined,
  isActive: false,
  details: {
    logo: '',
    icon: '',
    banner: '',
    currency: '',
    name: '',
    currencyNames: '',
    description: '',
    howToGet: '',
    howToUse: '',
    howToParticipate: '',
    inputMask: '',
    inputRegularExpression: '',
    placeholder: '',
    canAuthorize: false,
    canRegister: false,
    authorizeType: 'delayed_charges',
    hint: '',
    caption: '',
    signupNotice: '',
    signupResultMessage: '',
    signupResultButtonText: '',
  },
}

export function useGetPrograms<T extends string | string[]>(mnemocode: T): ProgramOf<T> {
  const programs = useSelector(ProgramWithBalanceSelector)
  if (Array.isArray(mnemocode)) {
    return programs.filter((program) => mnemocode.includes(program.programMnemocode)) as ProgramOf<T>
  }
  const currentProgram = programs.find((program) => program.programMnemocode === mnemocode) as ProgramOf<T>

  return currentProgram ?? (programFallback as ProgramOf<T>)
}

export function useGetAuthorizedPrograms() {
  const entries = useSelector(activeEntriesSelector)
  const mnemocodes = entries.map((entry) => entry.product)
  return useGetPrograms(mnemocodes)
}

export function useGetAllPrograms() {
  const allPrograms = useSelector(ProgramWithBalanceSelector)

  // todo: этот фильтр вообще нужен? в useGetProgramsWithActiveProducts, например, он не используется
  return allPrograms.filter(Boolean)
}

export function useGetAllProgramsByCurrency() {
  return useSelector(ProgramsByCurrencySelector)
}

export function useGetProgramsWithActiveProducts() {
  const programs = useSelector(ProgramWithBalanceSelector)
  return programs
    .filter(({ programMnemocode }) => !specialCashbackPrograms.includes(programMnemocode))
    .filter((program) => program.presentProduct?.length || program.futureProduct?.length)
}

export function useGetAvailablePrograms(
  productEntryClass: string | null,
  optionId: number | null | undefined,
  conflictedProgramId?: string
) {
  const programs = useSelector(ProgramWithBalanceSelector)
  const products = useSelector(ProductSelector)
  const productPrograms = useSelector(productProgramsByProductSelector)
  const product = products?.find((currentProduct) => currentProduct?.product?.product_class === productEntryClass)
  const currentProductClass = product?.product?.product_class
  const availablePrograms =
    currentProductClass && productPrograms[currentProductClass] ? productPrograms[currentProductClass] : []

  return programs
    .filter(({ programMnemocode }) => !specialCashbackPrograms.includes(programMnemocode))
    .filter((program) => productEntryClass && program.availableProducts?.includes(productEntryClass))
    .filter((program) => availablePrograms.includes(program.entryClass))
    .filter((program) =>
      optionId ? program.availableOptions.includes(optionId) || program.programMnemocode === conflictedProgramId : true
    )
}
