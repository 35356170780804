/* eslint-disable max-len */

export const Cards = () => (
  <path d="M18 5H6a3 3 0 00-3 3v8a3 3 0 003 3h12a3 3 0 003-3V8a3 3 0 00-3-3zM3 10h18M7 15h.01M11 15h2" />
)

export const Home = () => (
  <path d="M9 21v-6a2 2 0 012-2h2a2 2 0 012 2v6M5 12H3l9-9 9 9h-2v7a2 2 0 01-2 2H7a2 2 0 01-2-2v-7z" />
)

export const Exchanged = () => (
  <>
    <path d="M5 20a2 2 0 100-4 2 2 0 000 4zM19 8a2 2 0 100-4 2 2 0 000 4zM19 8v5a5 5 0 01-5 5h-3m0 0l3-3m-3 3l3 3" />
    <path d="M5 16v-5a5 5 0 015-5h3m0 0l-3-3m3 3l-3 3" />
  </>
)

export const Flame = () => (
  <path d="M12 12c2-2.96 0-7-1-8 0 3.04-1.77 4.74-3 6a7.55 7.55 0 00-2 5 6 6 0 1012 0 9.39 9.39 0 00-2-5c-1.79 3-2.8 3-4 2z" />
)

export const Welcome = () => (
  <>
    <path d="M11 11.5v-1a1.5 1.5 0 113 0V12" />
    <path d="M17 12V5.5a1.5 1.5 0 113 0V16a6 6 0 01-6 6h-2 .2A6 6 0 017 19c-.31-.48-1.4-2.39-3.29-5.73a1.5 1.5 0 01.54-2.02 1.87 1.87 0 012.28.28L8 13" />
    <path d="M14 10.5a1.5 1.5 0 113 0V12M8 13V4.5a1.5 1.5 0 013 0V12" />
  </>
)

export const Action = () => (
  <>
    <path d="M9 14l6-6M9.5 9a.5.5 0 100-1 .5.5 0 000 1zM14.5 14a.5.5 0 100-1 .5.5 0 000 1z" />
    <path d="M5 21V5a2 2 0 012-2h10a2 2 0 012 2v16l-3-2-2 2-2-2-2 2-2-2-3 2z" />
  </>
)

export const Bonus = () => (
  <path d="M18 4H6a2 2 0 00-2 2v2c0 1.1.9 2 2 2h12a2 2 0 002-2V6a2 2 0 00-2-2zM18 14H6a2 2 0 00-2 2v2c0 1.1.9 2 2 2h12a2 2 0 002-2v-2a2 2 0 00-2-2z" />
)

export const Purchases = () => (
  <path d="M6 17a2 2 0 100 4 2 2 0 000-4zm0 0h11M6 17V3H4m13 14a2 2 0 100 4 2 2 0 000-4zm3-11l-1 7H6m4-3l6-6m-5 .5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
)

export const ExternalLink = () => (
  <path d="M11 7H6a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2v-5m-7 1L20 4m0 0h-5m5 0v5" />
)

export const Star = () => (
  <path d="M12 17.75L5.83 21 7 14.12 2 9.25l6.9-1L11.99 2l3.09 6.25 6.9 1-5 4.87L18.16 21 12 17.75z" />
)

export const Exit = () => (
  <path d="M14 8V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2h7a2 2 0 002-2v-2M7 12h14m0 0l-3-3m3 3l-3 3" />
)

export const Settings = () => (
  <>
    <path d="M10.32 4.32c.43-1.76 2.93-1.76 3.36 0a1.72 1.72 0 002.57 1.06c1.54-.94 3.3.83 2.37 2.37a1.72 1.72 0 001.06 2.57c1.76.43 1.76 2.93 0 3.36a1.72 1.72 0 00-1.06 2.57c.94 1.54-.83 3.3-2.37 2.37a1.72 1.72 0 00-2.57 1.06c-.43 1.76-2.93 1.76-3.36 0a1.72 1.72 0 00-2.57-1.06c-1.54.94-3.3-.83-2.37-2.37a1.72 1.72 0 00-1.06-2.57c-1.76-.43-1.76-2.93 0-3.36a1.72 1.72 0 001.06-2.57c-.94-1.54.83-3.3 2.37-2.37 1 .61 2.3.07 2.57-1.06z" />
    <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
  </>
)

export const Download = () => <path d="M4 17v2a2 2 0 002 2h12a2 2 0 002-2v-2M7 11l5 5 5-5M12 4v12" />

export const Tutorial = () => (
  <>
    <path d="M22 9L12 5 2 9l10 4 10-4zm0 0v6" />
    <path d="M6 10.6V16c0 .8.63 1.56 1.76 2.12 1.12.56 2.65.88 4.24.88 1.6 0 3.12-.32 4.24-.88C17.37 17.56 18 16.8 18 16v-5.4" />
  </>
)

export const Rules = () => (
  <path d="M15 21H6a3 3 0 01-3-3v-1h10v2a2 2 0 002 2zm0 0l2-14m0 0h2a2 2 0 10-2-2v2zm2-4H8a3 3 0 00-3 3v11M9 7h4M9 11h4" />
)

export const Mail = () => (
  <>
    <path d="M3 9l9 6 9-6-9-6-9 6z" />
    <path d="M21 9v10a2 2 0 01-2 2H5a2 2 0 01-2-2V9M3 19l6-6M15 13l6 6" />
  </>
)

export const Phone = () => (
  <path d="M5 4h4l2 5-2.5 1.5a11 11 0 005 5L15 13l5 2v4a2 2 0 01-2 2A16 16 0 013 6a2 2 0 012-2M15 7a2 2 0 012 2M15 3a6 6 0 016 6" />
)

export const Bubble = () => <path d="M4 21V8a3 3 0 013-3h10a3 3 0 013 3v6a3 3 0 01-3 3H8l-4 4zM12 8v3M12 14v.01" />

export const Book = () => <path d="M12 19a9 9 0 00-9 0V6a9 9 0 019 0m0 13a9 9 0 019 0V6a9 9 0 00-9 0m0 13V6" />

export const RoundCheck = () => <path d="M7.5 12l3 3 6-6m4.5 3a9 9 0 11-18 0 9 9 0 0118 0z" />

export const RoundStop = () => <path d="M12 21a9 9 0 100-18 9 9 0 000 18zM6 6l12 12" />

export const Warning = () => (
  <path d="M12 9v2m0 4v.01M5 19h14a2 2 0 001.84-2.75L13.74 4a2 2 0 00-3.5 0l-7.1 12.25A2 2 0 004.89 19" />
)

export const Clock = () => <path d="M12 7v5l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />

export const Calc = () => (
  <path d="M8 14v.01m4-.01v.01m4-.01v.01M8 17v.01m4-.01v.01m4-.01v.01M6 3h12a2 2 0 012 2v14a2 2 0 01-2 2H6a2 2 0 01-2-2V5a2 2 0 012-2zm3 4h6a1 1 0 011 1v1a1 1 0 01-1 1H9a1 1 0 01-1-1V8a1 1 0 011-1z" />
)

export const Change = () => (
  <path d="M21 11V8a2 2 0 00-2-2h-6m0 0l3 3m-3-3l3-3M3 13v3a2 2 0 002 2h6m0 0l-3-3m3 3l-3 3M4 3h4a1 1 0 011 1v4a1 1 0 01-1 1H4a1 1 0 01-1-1V4a1 1 0 011-1zm12 12h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4a1 1 0 011-1z" />
)

export const Calendar = () => (
  <path d="M16 3v4M8 3v4m-4 4h16M6 5h12a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V7a2 2 0 012-2zm2 10h2v2H8v-2z" />
)

export const Refund = () => (
  <path d="M15 14v-2a2 2 0 00-2-2H9m0 0l2-2m-2 2l2 2m-6 9V5a2 2 0 012-2h10a2 2 0 012 2v16l-3-2-2 2-2-2-2 2-2-2-3 2z" />
)

export const Stars = () => (
  <>
    <path d="m17.8 19.817-2.172 1.138c-.0645.0336-.137.0485-.2094.0432-.0725-.0052-.1421-.0305-.201-.0731-.0589-.0425-.1049-.1006-.1327-.1677s-.0364-.1407-.0249-.2124l.415-2.411-1.757-1.707c-.0524-.0507-.0896-.1151-.1072-.1859-.0175-.0708-.0149-.1451.0078-.2144.0226-.0694.0643-.131.1202-.1778.056-.0468.124-.0769.1962-.0869l2.428-.352 1.086-2.193c.0325-.0653.0825-.1203.1445-.1587.0621-.0384.1336-.0588.2065-.0588s.1444.0204.2065.0588c.062.0384.112.0934.1445.1587l1.086 2.193 2.428.352c.072.0104.1397.0407.1955.0875.0557.0469.0972.1084.1197.1775.0226.0692.0254.1434.008.214-.0173.0707-.0541.1351-.1062.186l-1.757 1.707.414 2.41c.0124.0719.0045.1458-.023.2133-.0275.0676-.0734.1261-.1324.1689-.0591.0428-.1289.0682-.2017.0733-.0727.0051-.1455-.0103-.2099-.0445z" />
    <path d="m6.2 19.817-2.172 1.138c-.06445.0336-.13697.0485-.20944.0432-.07247-.0052-.14205-.0305-.20097-.0731-.05891-.0425-.10485-.1006-.13268-.1677s-.03645-.1407-.02491-.2124l.415-2.411-1.757-1.707c-.05244-.0507-.08957-.1151-.10716-.1859-.01758-.0708-.0149-.1451.00773-.2144.02262-.0694.06429-.131.12024-.1778.05596-.0468.12394-.0769.19619-.0869l2.428-.352 1.086-2.193c.03248-.0653.08254-.1203.14455-.1587s.13351-.0588.20645-.0588c.07295 0 .14445.0204.20646.0588.062.0384.11206.0934.14454.1587l1.086 2.193 2.428.352c.072.0104.1397.0407.1955.0875.0557.0469.0972.1084.1197.1775.0226.0692.0254.1434.008.214-.0173.0707-.0541.1351-.1062.186l-1.757 1.707.414 2.41c.01243.0719.00445.1458-.02302.2133-.02747.0676-.07333.1261-.13239.1689-.05905.0428-.12892.0682-.20167.0733-.07276.0051-.14548-.0103-.20992-.0445z" />
    <path d="m12 9.81701-2.172 1.13799c-.06445.0336-.13697.0485-.20944.0432-.07247-.0052-.14205-.0305-.20097-.0731-.05891-.0425-.10485-.1006-.13268-.1677s-.03645-.1407-.02491-.2124l.415-2.41099-1.757-1.707c-.05244-.0507-.08957-.11512-.10716-.18591-.01758-.0708-.0149-.1451.00773-.21445.02262-.06934.06429-.13093.12025-.17772.05595-.0468.12393-.07692.19618-.08692l2.428-.352 1.086-2.193c.0325-.06532.0825-.12028.1445-.1587.0621-.03841.1336-.05877.2065-.05877s.1444.02036.2065.05877c.062.03842.112.09338.1445.1587l1.086 2.193 2.428.352c.072.01036.1397.04067.1955.08751.0557.04684.0972.10834.1197.17753.0226.06919.0254.14332.008.214-.0173.07068-.0541.1351-.1062.18596l-1.757 1.707.414 2.40999c.0124.0719.0045.1458-.023.2133-.0275.0676-.0734.1261-.1324.1689-.0591.0428-.1289.0682-.2017.0733-.0727.0051-.1455-.0103-.2099-.0445z" />
  </>
)

export const Details = () => (
  <path d="m8 5h-2c-.53043 0-1.03914.21071-1.41421.58579-.37508.37507-.58579.88378-.58579 1.41421v12c0 .5304.21071 1.0391.58579 1.4142.37507.3751.88378.5858 1.41421.5858h5.697m-3.697-16c0 1.10457.89543 2 2 2h2c1.1046 0 2-.89543 2-2m-6 0c0-1.10457.89543-2 2-2h2c1.1046 0 2 .89543 2 2m4 9v4h4m-4-4c-2.2091 0-4 1.7909-4 4s1.7909 4 4 4 4-1.7909 4-4m-4-4c2.2091 0 4 1.7909 4 4m-4-7v-4c0-.53043-.2107-1.03914-.5858-1.41421-.3751-.37508-.8838-.58579-1.4142-.58579h-2m-6 6h4m-4 4h3" />
)

export const History = () => (
  <path d="m12 8.00007v4.00003l2 2m-10.94995-3c.22409-2.19997 1.25026-4.24042 2.88273-5.73207 1.63247-1.49164 3.75696-2.33008 5.96812-2.35533 2.2112-.02526 4.3543.76443 6.0204 2.2184s2.7386 3.47045 3.0129 5.6647c.2743 2.1942-.2688 4.4127-1.5258 6.232-1.2569 1.8194-3.1397 3.1123-5.2891 3.6321-2.1493.5198-4.41484.2301-6.36426-.8138-1.94941-1.0439-3.44627-2.7689-4.20499-4.846m-.5 5v-5h5" />
)

export const CalendarLate = () => (
  <path d="m11.795 21h-6.795c-.53043 0-1.03914-.2107-1.41421-.5858-.37508-.3751-.58579-.8838-.58579-1.4142v-12c0-.53043.21071-1.03914.58579-1.41421.37507-.37508.88378-.58579 1.41421-.58579h12c.5304 0 1.0391.21071 1.4142.58579.3751.37507.5858.88378.5858 1.41421v4h-16m15 3v4h4m-4-4c-2.2091 0-4 1.7909-4 4s1.7909 4 4 4 4-1.7909 4-4m-4-4c2.2091 0 4 1.7909 4 4m-7-15v4m-8-4v4" />
)

export const Structure = () => (
  <path d="m6 15v-1c0-.5304.21071-1.0391.58579-1.4142.37507-.3751.88378-.5858 1.41421-.5858h8c.5304 0 1.0391.2107 1.4142.5858s.5858.8838.5858 1.4142v1m-6-6v3m-7 3h2c1.10457 0 2 .8954 2 2v2c0 1.1046-.89543 2-2 2h-2c-1.10457 0-2-.8954-2-2v-2c0-1.1046.89543-2 2-2zm12 0h2c1.1046 0 2 .8954 2 2v2c0 1.1046-.8954 2-2 2h-2c-1.1046 0-2-.8954-2-2v-2c0-1.1046.8954-2 2-2zm-6-12h2c1.1046 0 2 .89543 2 2v2c0 1.10457-.8954 2-2 2h-2c-1.10457 0-2-.89543-2-2v-2c0-1.10457.89543-2 2-2z" />
)

export const Question = () => (
  <path d="m12 15.8571v.0129m-1.4657-7.05086c.2101-.24611.4788-.43539.7813-.55039.3025-.11501.6291-.15205.9496-.1077.3206.04434.6249.16864.8848.36144.2599.19281.4671.44792.6025.74183.1355.29392.1948.61719.1725.94008-.0223.3228-.1255.6349-.3001.9073-.1746.2725-.4149.4967-.6989.652-.2839.1552-.6024.2365-.926.2363v1m9-1c0 4.9706-4.0294 9-9 9-4.97056 0-9-4.0294-9-9 0-4.97056 4.02944-9 9-9 4.9706 0 9 4.02944 9 9z" />
)

export const Arrow = () => <path d="m19 12h-14m14 0-4-4m4 4-4 4" />

export const Conversion = () => (
  <>
    <path d="M5.83331 20C6.93788 20 7.83331 19.1046 7.83331 18C7.83331 16.8954 6.93788 16 5.83331 16C4.72874 16 3.83331 16.8954 3.83331 18C3.83331 19.1046 4.72874 20 5.83331 20Z" />
    <path d="M19.8333 8C20.9379 8 21.8333 7.10457 21.8333 6C21.8333 4.89543 20.9379 4 19.8333 4C18.7287 4 17.8333 4.89543 17.8333 6C17.8333 7.10457 18.7287 8 19.8333 8Z" />
    <path d="M19.8333 8V13C19.8333 14.3261 19.3065 15.5979 18.3688 16.5355C17.4312 17.4732 16.1594 18 14.8333 18H11.8333M11.8333 18L14.8333 15M11.8333 18L14.8333 21" />
    <path d="M5.83331 16V11C5.83331 9.67392 6.3601 8.40215 7.29778 7.46447C8.23546 6.52678 9.50723 6 10.8333 6H13.8333M13.8333 6L10.8333 3M13.8333 6L10.8333 9" />
  </>
)

export const Time = () => (
  <path d="M10.1667 5V10L13.1667 13M19.1667 10C19.1667 14.9706 15.1373 19 10.1667 19C5.19619 19 1.16675 14.9706 1.16675 10C1.16675 5.02944 5.19619 1 10.1667 1C15.1373 1 19.1667 5.02944 19.1667 10Z" />
)

export const Categories = () => (
  <>
    <path d="M9.5 4H5.5C4.94772 4 4.5 4.44772 4.5 5V9C4.5 9.55228 4.94772 10 5.5 10H9.5C10.0523 10 10.5 9.55228 10.5 9V5C10.5 4.44772 10.0523 4 9.5 4Z" />
    <path d="M19.5 4H15.5C14.9477 4 14.5 4.44772 14.5 5V9C14.5 9.55228 14.9477 10 15.5 10H19.5C20.0523 10 20.5 9.55228 20.5 9V5C20.5 4.44772 20.0523 4 19.5 4Z" />
    <path d="M9.5 14H5.5C4.94772 14 4.5 14.4477 4.5 15V19C4.5 19.5523 4.94772 20 5.5 20H9.5C10.0523 20 10.5 19.5523 10.5 19V15C10.5 14.4477 10.0523 14 9.5 14Z" />
    <path d="M19.5 14H15.5C14.9477 14 14.5 14.4477 14.5 15V19C14.5 19.5523 14.9477 20 15.5 20H19.5C20.0523 20 20.5 19.5523 20.5 19V15C20.5 14.4477 20.0523 14 19.5 14Z" />
  </>
)

export const Boxes = () => (
  <path d="m7.43 9.54 4.57-1.5 4.57 1.5-4.55 1.49-1.6-0.524h-0.0278zm2.43 5.54c-0.0636 0.0016-0.128-0.0072-0.191-0.0272l-6.29-2.01c-0.353-0.113-0.497-0.519-0.288-0.815l1.9-2.7-1.9-2.7c-0.209-0.296-0.065-0.703 0.288-0.815l6.29-2.01c0.237-0.0754 0.497 0.0086 0.638 0.206l1.69 2.36 1.69-2.36c0.141-0.198 0.401-0.282 0.638-0.206l6.29 2.01c0.353 0.113 0.497 0.519 0.288 0.815l-1.9 2.7 1.9 2.7c0.209 0.296 0.065 0.703-0.288 0.815l-1.92 0.612v0.0087l-4.34 1.38c-0.0622 0.0198-0.126 0.0286-0.189 0.0272-0.186 0.0048-0.367-0.0802-0.477-0.233l-1.67-2.34-1.67 2.34c-0.109 0.153-0.289 0.238-0.475 0.233zm4.82 0.934 4.01-1.28v2.77c0 0.233-0.156 0.44-0.386 0.513l-6.15 1.95c-0.114 0.036-0.237 0.036-0.351 0l-6.15-1.95c-0.23-0.0729-0.386-0.28-0.386-0.513v-2.8l4.1 1.31c0.68 0.217 1.43-0.0246 1.83-0.593l0.821-1.15 0.821 1.15c0.407 0.568 1.15 0.81 1.83 0.593z" />
)

export const Cross = () => <path d="m18 6-12 12m0-12 12 12" />

export const Check = () => <path d="m5 12 5 5 10-10" />

export const Offers = () => (
  <path d="m9 14 6-6m-5 .5c0 .27614-.22386.5-.5.5s-.5-.22386-.5-.5.22386-.5.5-.5.5.22386.5.5zm5 5c0 .2761-.2239.5-.5.5s-.5-.2239-.5-.5.2239-.5.5-.5.5.2239.5.5zm-10 7.5v-16c0-.53043.21071-1.03914.58579-1.41421.37507-.37508.88378-.58579 1.41421-.58579h10c.5304 0 1.0391.21071 1.4142.58579.3751.37507.5858.88378.5858 1.41421v16l-3-2-2 2-2-2-2 2-2-2z" />
)

export const Help = () => (
  <path d="m21 21-6-6m-5-2v.01m0-3.01c.2517.0001.4994-.06309.7202-.18382.2209-.12073.4078-.2951.5436-.50704.1358-.21195.216-.45465.2334-.70575.0173-.25111-.0288-.50254-.1341-.73114-.1054-.2286-.2665-.42702-.4687-.57698-.2021-.14995-.4388-.24663-.6881-.28112-.24934-.03449-.50333-.00568-.73861.08376-.23527.08945-.44425.23667-.60769.42809m8.14 2.474c0 3.866-3.134 7-7 7-3.86599 0-7-3.134-7-7 0-3.86599 3.13401-7 7-7 3.866 0 7 3.13401 7 7z" />
)

export const Cash = () => (
  <path d="m17 9v-2c0-.53-.211-1.039-.586-1.414s-.884-.586-1.414-.586h-10c-.53 0-1.039.211-1.414.586s-.586.884-.586 1.414v6c0 .53.211 1.039.586 1.414s.884.586 1.414.586h2m2-6h10c1.105 0 2 .895 2 2v6c0 1.105-.895 2-2 2h-10c-1.105 0-2-.895-2-2v-6c0-1.105.895-2 2-2zm3.442 8.314v-6.284h2.694c.357 0 .7.142.952.395.253.252.395.595.395.952s-.142.7-.395.952c-.252.253-.595.395-.952.395h-3.591m3.591 1.795h-3.591" />
)

export const Basket = () => (
  <>
    <path d="m4.129 3.429 2.169 12.145c.192 1.073 1.125 1.855 2.215 1.855h8.21c1.1 0 2.039-.795 2.219-1.88l1.187-7.12" />
    <path d="m2.129 3.429h2" />
    <circle cx="8" cy="19.5" r="2" />
    <circle cx="17" cy="19.5" r="2" />
    <path d="m11.116 11.424 3.143-3.143m-2.619.261c0 .145-.117.262-.262.262s-.262-.117-.262-.262.117-.262.262-.262.262.118.262.262zm2.619 2.62c0 .145-.117.262-.262.262s-.262-.117-.262-.262.117-.262.262-.262.262.117.262.262zm-5.238-3.825c0-.306.121-.599.338-.815s.509-.338.815-.338h.524c.304 0 .596-.121.812-.335l.367-.367c.107-.108.234-.193.375-.252s.291-.088.443-.088.302.03.443.088.268.144.375.252l.367.367c.216.215.508.335.812.335h.524c.306 0 .599.121.815.338s.338.509.338.815v.524c0 .304.121.596.335.812l.367.367c.108.107.193.234.252.375.058.14.088.291.088.443s-.03.302-.088.442-.144.268-.252.375l-.367.367c-.215.216-.335.508-.335.812v.524c0 .306-.121.599-.338.815s-.509.338-.815.338h-.524c-.304 0-.596.121-.812.335l-.367.367c-.107.108-.234.193-.375.252-.14.058-.291.088-.443.088s-.302-.03-.443-.088c-.14-.058-.268-.144-.375-.252l-.367-.367c-.216-.215-.508-.335-.812-.335h-.524c-.306 0-.599-.121-.815-.338s-.338-.509-.338-.815v-.524c0-.304-.121-.596-.335-.812l-.367-.367c-.108-.108-.194-.235-.252-.375s-.088-.291-.088-.442c0-.152.03-.302.088-.443.058-.14.144-.268.252-.375l.367-.367c.214-.216.334-.508.335-.812z" />
  </>
)

export const Ticket = () => (
  <>
    <path d="m15 5v2m0 4v2m0 4v2m-10-14h14c.53 0 1.039.211 1.414.586s.586.884.586 1.414v3c-.53 0-1.039.211-1.414.586s-.586.884-.586 1.414.211 1.039.586 1.414.884.586 1.414.586v3c0 .53-.211 1.039-.586 1.414s-.884.586-1.414.586h-14c-.53 0-1.039-.211-1.414-.586s-.586-.884-.586-1.414v-3c.53 0 1.039-.211 1.414-.586s.586-.884.586-1.414-.211-1.039-.586-1.414-.884-.586-1.414-.586v-3c0-.53.211-1.039.586-1.414s.884-.586 1.414-.586z" />
    <path d="m12 9.952-4.001 3.914" />
    <path d="m7.723 10.226 2.871 1.105" />
    <path d="m11.632 14.226-1.038-2.895" />
    <path d="m7.4 13.253 1.151 1.178" />
  </>
)

export const User = () => (
  <path d="m6 21v-2c0-1.0609.42143-2.0783 1.17157-2.8284.75015-.7502 1.76756-1.1716 2.82843-1.1716h4c1.0609 0 2.0783.4214 2.8284 1.1716.7502.7501 1.1716 1.7675 1.1716 2.8284v2m-2-14c0 2.20914-1.7909 4-4 4-2.20914 0-4-1.79086-4-4s1.79086-4 4-4c2.2091 0 4 1.79086 4 4z" />
)

export const Globe = () => (
  <>
    <circle cx="12" cy="12" r="9" />
    <ellipse cx="12" cy="12" rx="4.855" ry="9" />
    <path d="m3 12h18" />
    <path d="m12 3v18" />
    <path d="m5.02 6.322c2.207 1.04 4.55 1.629 6.98 1.629s4.773-.589 6.98-1.629" />
    <path d="m18.98 17.678c-2.207-1.04-4.55-1.629-6.98-1.629s-4.773.589-6.98 1.629" />
  </>
)

export const Bolt = () => <path d="m13 3v7h6l-8 11v-7h-6z" />

export const Bank = () => (
  <>
    <path d="m4.5 12v7m15-7v7m1 2h-17m-.5-11c3.515-2.734 9-7 9-7s5.485 4.266 9 7z" />
    <path d="m4.5 12v7m15-7v7m1 2h-17m-.5-11c3.515-2.734 9-7 9-7s5.485 4.266 9 7z" />
    <path d="m10.5 19v-5.6c0-.371-.158-.727-.439-.99-.282-.262-.663-.41-1.061-.41s-.779.148-1.061.41c-.281.263-.439.619-.439.99v5.6" />
    <path d="m16.5 19v-5.6c0-.371-.158-.727-.439-.99-.282-.262-.663-.41-1.061-.41s-.779.148-1.061.41c-.281.262-.439.619-.439.99v5.6" />
  </>
)

export const Hello = () => (
  <path d="m11 11.5v-1c0-.398.158-.779.439-1.061.282-.281.663-.439 1.061-.439s.779.158 1.061.439c.281.282.439.663.439 1.061m0 0v1.5m0-1.5c0-.398.158-.779.439-1.061.282-.281.663-.439 1.061-.439s.779.158 1.061.439c.281.282.439.663.439 1.061v1.5-6.5c0-.398.158-.779.439-1.061s.663-.439 1.061-.439.779.158 1.061.439.439.663.439 1.061v10.5c0 1.591-.632 3.117-1.757 4.243-1.126 1.125-2.652 1.757-4.243 1.757h-2 .208c-.994 0-1.972-.247-2.847-.718-.874-.471-1.618-1.152-2.165-1.982-.066-.1-.131-.2-.196-.3-.312-.479-1.407-2.388-3.286-5.728-.192-.34-.243-.742-.143-1.12s.344-.701.679-.902c.357-.214.775-.303 1.188-.252s.797.238 1.092.532l1.47 1.47v-8.5c0-.398.158-.779.439-1.061.282-.281.663-.439 1.061-.439s.779.158 1.061.439c.281.282.439.663.439 1.061v7.5" />
)

export const Gift = () => (
  <path d="m12 8v13m0-13c-.362-1.491-.985-2.765-1.787-3.657-.803-.891-1.748-1.359-2.713-1.343-.663 0-1.299.264-1.768.733-.469.468-.732 1.104-.732 1.767s.263 1.299.732 1.768 1.105.732 1.768.732m4.5 0c.362-1.491.985-2.765 1.787-3.657s1.748-1.359 2.713-1.343c.663 0 1.299.263 1.768.732s.732 1.105.732 1.768-.263 1.299-.732 1.768-1.105.732-1.768.732m2.5 4v7c0 .53-.211 1.039-.586 1.414s-.884.586-1.414.586h-10c-.53 0-1.039-.211-1.414-.586s-.586-.883-.586-1.414v-7m-1-4h16c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1h-16c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1z" />
)

export const Wallet = () => (
  <path d="m17 8v-3c0-.265-.105-.52-.293-.707-.187-.188-.442-.293-.707-.293h-10c-.53 0-1.039.211-1.414.586s-.586.884-.586 1.414m0 0c0 .53.211 1.039.586 1.414s.884.586 1.414.586h12c.265 0 .52.105.707.293.188.187.293.442.293.707v3m-15-6v12c0 .53.211 1.039.586 1.414s.884.586 1.414.586h12c.265 0 .52-.105.707-.293s.293-.442.293-.707v-3m1-4v4h-4c-.53 0-1.039-.211-1.414-.586s-.586-.884-.586-1.414.211-1.039.586-1.414.884-.586 1.414-.586z" />
)

export const ConversionToRoubles = () => (
  <>
    <path d="m7.5 13h-5m2.5-2.5v5" />
    <path d="m12 19v-14h6c.796 0 1.559.316 2.121.879.563.562.879 1.325.879 2.121s-.316 1.559-.879 2.121c-.562.563-1.325.879-2.121.879h-8m8 4h-8" />
  </>
)

export const ReturnFromMarketplace = () => (
  <>
    <path d="m12.004 14.589-3.863 2.034.738-4.307-3.129-3.051 4.319-.627 1.931-3.918 1.931 3.919 4.319.627-3.129 3.05.738 4.307z" />
    <path d="m5 5v16l3-2 2 2 2-2 2 2 2-2 3 2v-16c0-.53-.211-1.039-.586-1.414s-.884-.586-1.414-.586h-10c-.53 0-1.039.211-1.414.586s-.586.884-.586 1.414z" />
    <path d="m13.479 13.041v-1.102c0-.292-.115-.573-.319-.78s-.481-.323-.77-.323h-2.177m1.089 1.102-1.088-1.102 1.088-1.102" />
  </>
)

export const Link = () => (
  <>
    <path d="m12.75 4.59.259-.259c.435-.422.951-.757 1.518-.985.568-.228 1.177-.346 1.792-.346s1.224.117 1.792.345 1.084.563 1.519.984c.435.422.78.922 1.015 1.473.234.552.355 1.143.355 1.739s-.121 1.187-.357 1.738-.581 1.051-1.016 1.473l-3.009 2.918c-.435.422-.951.756-1.518.984-.567.229-1.176.346-1.791.346s-1.223-.117-1.791-.346c-.568-.228-1.084-.562-1.518-.984" />
    <path d="m11.184 18.75-.919.919c-.395.422-.864.757-1.38.985-.517.228-1.07.346-1.629.346s-1.112-.117-1.629-.345c-.516-.228-.986-.563-1.381-.984-.395-.422-.709-.922-.923-1.473-.213-.552-.323-1.143-.323-1.739s.11-1.187.324-1.738.528-1.051.923-1.473l2.735-2.918c.395-.422.864-.756 1.38-.984.517-.229 1.071-.346 1.629-.346.559 0 1.112.117 1.628.346.516.228.985.563 1.38.984" />
  </>
)

export const VK = () => (
  <path d="m18.306 17.346c-.501 0-.741-.238-1.361-.926-.264-.293-.557-.619-.945-1.007-1.183-1.141-1.717-1.272-2.016-1.272-.224 0-.415.165-.446.388-.008.056-.016.146-.016.287v1.766c0 .448-.101.766-1.406.766-2.189 0-4.435-1.347-6.007-3.604-2.373-3.327-3.109-5.952-3.109-6.532 0-.167 0-.557.675-.557h1.969c.527 0 .698.236.882.766.904 2.637 2.267 4.664 2.902 5.113.077.054.168.083.26.083.065 0 .131-.014.192-.043.146-.069.245-.212.257-.374.005-.067.009-.155.009-.274v-2.9c-.045-.827-.335-1.186-.547-1.447-.146-.18-.235-.299-.235-.472 0-.182.159-.453.501-.453h3.094c.272 0 .563.056.563.723v3.902c0 .167.094.345.242.423.076.04.161.063.244.063.078 0 .155-.021.221-.067.123-.085.278-.223.517-.461 1.408-1.574 2.414-4.011 2.423-4.034.159-.371.432-.55.854-.55h1.97c.275 0 .464.066.561.197.114.152.082.37.035.526-.2.911-1.76 3.271-2.652 4.528-.118.191-.157.273-.177.358-.026.114-.007.234.054.333.017.032.051.091.121.182.105.144.326.362.56.592.273.267.566.554.798.829 1.011 1.136 1.506 1.824 1.662 2.308.072.252.057.459-.051.602-.114.156-.331.236-.63.236h-1.968z" />
)

export const Telegram = () => (
  <>
    <path d="m8.362 12.806 8.07 7.113c.297.263.751.236 1.014-.062.08-.09.135-.199.162-.316l3.375-14.766c.086-.388-.158-.772-.545-.859-.139-.031-.283-.02-.416.031l-16.57 6.52c-.664.261-.574 1.232.126 1.376z" />
    <path d="m8.362 12.806 12.217-8.839" />
    <path d="m12.392 16.358-2.801 2.806c-.278.283-.733.287-1.016.008-.137-.135-.214-.319-.214-.511v-5.854" />
  </>
)

export const Viber = () => (
  <>
    <path d="m8.082 21c-.001 0-.001 0-.001 0-.003-.001-.004-.003-.004-.005v-2.318l-.222-.088c-1.195-.474-2.211-1.289-2.938-2.357-.728-1.07-1.114-2.318-1.114-3.609v-3.209c0-3.537 2.878-6.414 6.416-6.414h3.562c3.538 0 6.416 2.877 6.416 6.414v3.209c0 3.537-2.878 6.414-6.416 6.414h-3.53l-2.165 1.961c-.001.002-.003.002-.004.002z" />
    <path d="m14.54 15.656c-.276 0-.544-.08-.776-.23l-.017-.011-.018-.009c-.025-.012-2.62-1.354-4.047-2.807-1.4-1.426-2.28-2.989-2.316-3.054-.217-.596-.425-1.454.257-2.048l1.075-.934s.001-.001.004-.001.003.001.004.002l1.712 1.97c.26.299.228.754-.07 1.013l-.438.38.077.224c.303.877.729 1.526 1.301 1.983.495.395 1.031.592 1.538.759l.257.085.25-.385c.105-.162.266-.273.453-.312.048-.01.099-.015.149-.015.138 0 .274.04.391.117l2.189 1.422c.002.002.004.006.001.009l-.775 1.194c-.264.405-.714.648-1.201.648z" />
    <path d="m12.669 4.7c2.692.721 4.837 2.721 5.59 5.213" />
    <path d="m12.857 6.599c.97.188 2.782 1.162 3.446 3.214" />
    <path d="m12.904 8.58c.671.125 1.36.767 1.608 1.5" />
  </>
)

export const GiftCertificate = () => (
  <>
    <path d="m19 5h-14c-.53 0-1.039.211-1.414.586s-.586.884-.586 1.414v3 7c0 .53.211 1.039.586 1.414.375.376.884.586 1.414.586h14c.53 0 1.039-.211 1.414-.586s.586-.883.586-1.414v-3-4-3c0-.53-.211-1.039-.586-1.414s-.884-.586-1.414-.586z" />
    <path d="m10.5 5v14" />
    <path d="m16.5 11.25h4.5" />
    <path d="m3 11.25h1.5" />
    <path d="m5.107 8.093c1.465-2.055 4.674 1.749 5.393 3.673-1.407.068-3.474 1.783-4.9.525-1.256-1.109-1.459-2.843-.493-4.198z" />
    <path d="m15.893 8.093c-1.465-2.055-4.674 1.749-5.393 3.673 1.407.068 3.474 1.783 4.9.525 1.256-1.109 1.459-2.843.493-4.198z" />
    <path d="m5.25 15.001s2.085-.317 3.15-1.071c1.078-.764 2.1-2.679 2.1-2.679s1.022 1.914 2.1 2.679c1.065.755 3.15 1.071 3.15 1.071" />
  </>
)

export const Cashback = () => (
  <>
    <path d="M17.7999 5.92365C16.292 4.48397 14.2492 3.59985 11.9999 3.59985C7.36066 3.59985 3.59985 7.36066 3.59985 11.9999C3.59985 16.639 7.36066 20.3999 11.9999 20.3999C16.639 20.3999 20.3999 16.639 20.3999 11.9999V10.3999M20.3999 10.3999L19.1999 11.9999M20.3999 10.3999L21.5999 11.9999" />
    <path d="M12 7.20001L13.4106 10.0584L16.565 10.5167L14.2825 12.7417L14.8213 15.8833L12 14.4L9.17858 15.8833L9.71742 12.7417L7.43488 10.5167L10.5893 10.0584L12 7.20001Z" />
  </>
)

export const Chevron = () => <path d="m10.5 7 5 5-5 5" />

export const MagnifyingGlass = () => (
  <>
    <path d="m17.302 17.003 4.167 4.167" />
    <circle cx="12" cy="11" r="7.5" />
  </>
)

export const Sort = () => <path d="m3 9 4-4m0 0 4 4m-4-4v14m14-4-4 4m0 0-4-4m4 4v-14" />

export const EyeOn = () => (
  <>
    <path d="m12 6.167c-6.548 0-9.167 5.834-9.167 5.834s2.619 5.832 9.167 5.832 9.167-5.833 9.167-5.833-2.619-5.833-9.167-5.833z" />
    <path d="m12 15.333c1.841 0 3.333-1.492 3.333-3.333s-1.492-3.333-3.333-3.333-3.333 1.492-3.333 3.333 1.492 3.333 3.333 3.333z" />
  </>
)

export const EyeOff = () => (
  <path d="m5.478 4.826 13.043 14.347m-10.924-12.015c-3.323 1.683-4.727 4.843-4.727 4.843s2.609 5.869 9.13 5.869c1.528.012 3.037-.34 4.402-1.027m2.169-1.493c1.778-1.592 2.559-3.35 2.559-3.35s-2.608-5.87-9.13-5.87c-.565-.001-1.129.045-1.686.137m2.3 2.53c.693.133 1.324.487 1.799 1.009s.767 1.184.834 1.887m-1.583 2.809c-.64.582-1.485.885-2.349.844s-1.676-.424-2.257-1.064c-.582-.64-.885-1.485-.844-2.348.041-.864.424-1.676 1.063-2.258" />
)

export const Copy = () => (
  <path d="m20 17.553v-12.553c0-.552-.448-1-1-1h-12.553m10.259 15v-10.706c0-.552-.448-1-1-1h-10.706c-.552 0-1 .448-1 1v10.706c0 .552.448 1 1 1h10.706c.552 0 1-.448 1-1z" />
)

export const Edit = () => (
  <path d="m15.306 5.8423 2.852 2.852m-10.156-0.696h-3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844-3.565 0.713 0.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0z" />
)
