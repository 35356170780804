import { createApi } from '@reduxjs/toolkit/query/react'
import { createAxiosRTQuery } from '~/lib/api/createAxiosRTQuery'
import type { CurrencyExchangeRates } from '~/models/balance'
import type { RestrictionsDataType } from '~/models/settings'
import { gatewayApiQuery, getGatewayApiUrlFromQueryApi, shouldUseGatewayApi } from '~/lib/gatewayApi'
import { X5ClientIsAvailableResponse } from '~/models'
import { getFetchConfigParamsData } from './utils/getFetchConfigParamsData'

const axiosRTQuery = createAxiosRTQuery()

export const miscApi = createApi({
  reducerPath: 'misc',
  baseQuery: axiosRTQuery({
    baseUrl: '/',
  }),
  endpoints: (build) => ({
    getOttLink: build.query<string, void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode, company } = getFetchConfigParamsData(queryApi)

        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `00000001/v2/aol/profile/${profile_mnemocode}/ottlink?partner=${company}`,
              method: 'GET',
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/ott-link`,
              method: 'GET',
              params: {
                partner: company,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const resData = res.data as unknown as { data: string }

        return { data: resData.data }
      },
    }),
    getCurrencyExchange: build.query<CurrencyExchangeRates, { currencyFrom: string; currencyTo: string }>({
      async queryFn({ currencyFrom, currencyTo }, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { company } = getFetchConfigParamsData(queryApi)

        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: 'metadata/currency/exchange',
              method: 'GET',
              params: {
                currencyFrom,
                currencyTo,
              },
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/currency/exchange`,
              method: 'GET',
              params: {
                currencyFrom,
                currencyTo,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const data = res.data as unknown as CurrencyExchangeRates[]

        return { data: data[0] }
      },
    }),
    getRestrictions: build.query<RestrictionsDataType, void>({
      query: () => ({ url: `brothers/api/partner/restrictions`, method: 'get' }),
      transformResponse: (response: { data: RestrictionsDataType }) => response.data,
    }),
    checkX5ClientAvailability: build.query<boolean, string>({
      query: (payload) => ({
        url: `integration-bus/x5group/client-available`,
        method: 'POST',
        data: { mobile_phone: payload },
      }),
      transformResponse: (response: { data: X5ClientIsAvailableResponse }) => response.data.available,
    }),
  }),
})

export const {
  useGetOttLinkQuery,
  useGetCurrencyExchangeQuery,
  useGetRestrictionsQuery,
  useCheckX5ClientAvailabilityQuery,
} = miscApi
