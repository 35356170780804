import { useGetProfileQuery } from '~/store/api/profile'
// import { useCheckX5ClientAvailabilityQuery } from '~/store/api/misc'

export function useGetX5AuthorizationData(isX5?: boolean) {
  const { data: profileData } = useGetProfileQuery()
  const { primary_phone } = profileData!
  const userPhone = primary_phone.replace('+', '')

  // const { data: x5ServerResponse } = useCheckX5ClientAvailabilityQuery(userPhone.slice(1), { skip: !isX5 })
  // const isX5ClientExists = typeof x5ServerResponse !== 'undefined' && x5ServerResponse

  return { userPhone }
}
