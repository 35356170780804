import React, {  useEffect } from 'react'
import { useTypedSelector } from '~/store/redux-store'
import { useGetPrograms } from '~/hooks/useGetPrograms'
import { AuthorizationRegular } from './AuthorizationRegular'
import { AuthorizationX5 } from './AuthorizationX5'

interface Props {
  onNextClick?: () => void
}

export function Authorization({ onNextClick }: Props) {
  const selectedProgram = useTypedSelector(({ process }) => process.selectedProgram)
  const { hasEntry, entryClass } = useGetPrograms(selectedProgram as string)

  useEffect(() => {
    if (onNextClick && hasEntry) onNextClick()
  }, [hasEntry, onNextClick])

  return entryClass === 'X5G' ? <AuthorizationX5 /> : <AuthorizationRegular />
}
