import { PlatformStatus, ProgramEntry, ProgramModel } from '~/models'
import { createSelector } from 'reselect'
import { keyBy } from 'lodash'
import { activeEntriesSelector } from '~/store/api/entries'
import { ProductOptionSelector } from '~/store/selector/products'
import { programsQuerySelector } from '~/store/api/programs'
import { productProgramsAllProgramsSelector, optionsByProgramSelector } from '~/store/api/options'
import { balancesTotalQuerySelector, lastCountedPeriodsQuerySelector } from '~/store/api/balance'

const ProgramModelFactory = ({
  program,
  entry,
  optionsByProgram,
  availableProducts,
  availableOptions,
}: ProgramEntry): ProgramModel => {
  return {
    programMnemocode: program.mnemocode,
    hasEntry: Boolean(entry && Object.keys(entry) && entry.constructor === Object),
    entryMnemocode: entry?.mnemocode,
    entryName: entry?.name,
    balanceTotal: null,
    balanceTotalProfit: null,
    hasTransactions: false,
    entryStatus: entry?.status ?? PlatformStatus.NotAuthorized,
    entryClass: program.productClass,
    isBankProgram: program.components.isPartnerProgram ?? false,
    createdWhen: entry?.created_when,
    isActive: program.isActive,
    details: {
      inputMask: program.components?.entry_nr_input_mask,
      inputRegularExpression: program.components.entry_nr_mask,
      logo: program.components.logo,
      icon: program.components.icon,
      banner: program.components.banner,
      currency: program.currency,
      name: program.components.name,
      currencyNames: program.components.currency_names,
      description: program.components.description,
      howToGet: program.components.howToGet,
      howToParticipate: program.components.howToParticipate,
      howToUse: program.components.howToUse,
      placeholder: program.components.placeholder,
      canAuthorize: program.components.canAuthorize,
      canRegister: program.components.canRegister,
      authorizeType: program.components.authorizeType ?? 'delayed_charges',
      hint: (program.components.hint ?? '').trim(),
      caption: (program.components.caption ?? '').trim(),
      signupNotice: (program.components.signup_notice ?? '').trim(),
      signupResultMessage: (program.components.signup_result_message ?? '').trim(),
      signupResultButtonText: (program.components.signup_result_button_text ?? '').trim(),
    },
    presentProduct: [],
    futureProduct: [],
    optionsByProgram: optionsByProgram ?? [],
    availableProducts: availableProducts ?? [],
    availableOptions: availableOptions ?? [],
  }
}

export const ProgramSelector = createSelector(
  activeEntriesSelector,
  programsQuerySelector,
  optionsByProgramSelector,
  (entries, programs, optionsByProgram) => {
    return programs
      .map((program) => ({
        program,
        entry: entries.find((entry) => entry.entry_class === program.productClass),
        optionsByProgram: optionsByProgram[program.productClass]?.map(
          (currentOption) => currentOption.product_option_id
        ),
        availableProducts: optionsByProgram[program.productClass]?.map((currentOption) => currentOption.productClass),
        availableOptions: optionsByProgram[program.productClass]?.map((currentOption) => currentOption.optionId),
      }))
      .map(ProgramModelFactory)
  }
)

const AccessibleProgramsSelector = createSelector(
  ProgramSelector,
  productProgramsAllProgramsSelector,
  (programs, productPrograms) => {
    return programs.filter((program) => productPrograms.includes(program.entryClass))
  }
)

const ProgramWithProductsSelector = createSelector(
  AccessibleProgramsSelector,
  ProductOptionSelector,
  (programs, products) => {
    return programs.map((program) => {
      const presentProduct = products.filter(
        (product) =>
          product?.presentOption && program?.optionsByProgram?.includes(product?.presentOption?.productOptionId)
      )
      const futureProduct = products.filter(
        (product) =>
          product?.futureOption && program?.optionsByProgram?.includes(product?.futureOption?.productOptionId)
      )
      return {
        ...program,
        presentProduct,
        futureProduct,
      }
    })
  }
)

export const ProgramWithBalanceSelector = createSelector(
  ProgramWithProductsSelector,
  balancesTotalQuerySelector,
  lastCountedPeriodsQuerySelector,
  (programs, balancesTotal, periods) => {
    const programsWithBalance = programs.map((program) => {
      const lastCountedPeriod = periods[program.details.currency]
      const { currency } = program.details
      if (lastCountedPeriod) {
        program.lastCountedPeriod = lastCountedPeriod
        program.balanceTotal = balancesTotal[currency]?.total ?? null
        program.balanceTotalProfit = balancesTotal[currency]?.totalProfit ?? null
        program.hasTransactions = Boolean(balancesTotal[currency]?.totalProfit)
      }
      return program
    })
    const linkedPrograms = moveBanksProgramsFirst(
      programsWithBalance.filter((program) => program.presentProduct.length || program.futureProduct.length)
    )
    const unlinkedPrograms = programsWithBalance.filter(
      (program) => !program.presentProduct.length && !program.futureProduct.length
    )
    const banksPrograms = moveChargedProgramsFirst(unlinkedPrograms.filter((program) => program.isBankProgram))
    const nonBanksPrograms = unlinkedPrograms.filter((program) => !program.isBankProgram)
    const hasEntryPrograms = nonBanksPrograms.filter((program) => program.hasEntry)
    const hasStatusAuthorizedPrograms = moveChargedProgramsFirst(
      hasEntryPrograms.filter((program) => program.entryStatus === 'I')
    )
    const hasOtherStatusesPrograms = hasEntryPrograms.filter((program) => program.entryStatus !== 'I')
    const notAuthorizedPrograms = nonBanksPrograms.filter((program) => !program.hasEntry)

    return [
      ...linkedPrograms,
      ...banksPrograms,
      ...hasStatusAuthorizedPrograms,
      ...hasOtherStatusesPrograms,
      ...notAuthorizedPrograms,
    ]
  }
)

export const ProgramsByCurrencySelector = createSelector(
  ProgramWithBalanceSelector,
  (programs) => {
    return keyBy(programs, 'details.currency')
  }
)

function moveChargedProgramsFirst(programs: ProgramModel[]) {
  const charged = programs.filter((program) => program.hasTransactions)
  const unCharged = programs.filter((program) => !program.hasTransactions)
  return [...charged, ...unCharged]
}

function moveBanksProgramsFirst(programs: ProgramModel[]) {
  const bankPrograms = programs.filter((program) => program.isBankProgram)
  const otherPrograms = programs.filter((program) => !program.isBankProgram)
  return [...bankPrograms, ...otherPrograms]
}
